.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


/* App.css */

/* Background for larger screens is set in App.js inline styles */

/* Background for screens smaller than a certain breakpoint, e.g., 768px */
@media (max-width: 1125px) {
  .appBackground {
    background-image: url("/public/mobilebackground2.png") !important;
  }
}
.appBackground {
  background-image: url("/public/background7.png");
  /* rest of your styles */
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #2fadd1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
